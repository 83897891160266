<template>
  <!-- 新闻中心 -->
  <div
    class="news"
    v-if="$store.state.pageset.pcshow"
    v-loading.fullscreen.lock="doSyncLoading"
  >
    <el-tabs v-model="activeName" @tab-click="getNewsList(false)">
      <el-tab-pane label="七腾视频" name="one">
        <div class="news-video">
          <div class="header">
            <el-input
              size="mini"
              class="news-search"
              placeholder="七腾大事件"
              v-model="elmentPar.searchValue"
              @keyup.enter.native="getNewsList(false)"
            >
              <i
                class="el-icon-search el-input__icon"
                slot="suffix"
                @click="getNewsList(false)"
              >
              </i>
            </el-input>
            <el-pagination
              background
              layout="prev, pager, next"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="elmentPar.pageNum"
              :page-size="elmentPar.pageSize"
              :total="newsTotal"
            >
            </el-pagination>
          </div>
          <div class="video-con" v-if="newsVideoSwiper.length>0" >
            <div class="con-top">
              <div class="img-swiper">
                <el-carousel :interval="3000" arrow="always">
                  <el-carousel-item
                    v-for="(item, index) in newsVideoSwiper"
                    :key="index"
                    @click.stop="openNewsDetails(item,'pc')"
                  >
                    <video
                      muted
                      preload
                      type="video/mp4"
                      :src="item.newsPcImg"
                    ></video>
                    <p class="swiper-title">{{ item.name }}</p>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="top-right">
                <div
                  class="video-item"
                  v-for="(item, index) in newsVideoTop"
                  :key="index"
                  @click="openNewsDetails(item,'pc')"
                >
                  <div class="item-src" title="点击播放/暂停">
                    <video
                      muted
                      preload
                      controls
                      type="video/mp4"
                      :src="item.newsPcImg"
                    ></video>
                    <p class="item-time">
                      {{ item.duration }}&nbsp;&nbsp;&nbsp;
                    </p>
                  </div>
                  <div class="item-con">
                    <el-tooltip
                      class="item"
                      :content="item.name"
                      effect="dark"
                      placement="top-start"
                    >
                      <p class="dec-title">{{ item.name }}</p>
                    </el-tooltip>
                    <p class="dec-time">
                      <span> {{ item.issueDate }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="con-down">
              <div
                class="video-item"
                v-for="(item, index) in newsVideoDown"
                :key="index"
                @click="openNewsDetails(item,'pc')"
              >
                <div class="item-src">
                  <video
                    title="点击播放/暂停"
                    muted
                    preload
                    controls
                    type="video/mp4"
                    :src="item.newsPcImg"
                  ></video>
                  <p class="item-time">{{ item.duration }}&nbsp;&nbsp;&nbsp;</p>
                </div>
                <div class="item-con">
                  <el-tooltip
                    class="item"
                    :content="item.name"
                    effect="dark"
                    placement="top-start"
                  >
                    <p class="dec-title">{{ item.name }}</p>
                  </el-tooltip>
                  <p class="dec-time">
                    <span>{{ item.issueDate }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 右侧商标栏目 -->
        <div class="app-item">
          <div v-for="(item, index) in appConList" :key="index">
            <img
              v-if="item.url"
              :src="item.imagePath"
              @click="connection(item.url)"
            />
            <!-- 公众号视频号二维码 -->
            <el-popover v-else placement="left" width="150" trigger="click">
              <div style="text-align: center">
                <img
                  v-if="item.name === '公众号'"
                  src="./image/wChart.jpg"
                  width="100%"
                  height="100%"
                  style="object-fit: cover"
                />
                <img
                  v-if="item.name === '视频号'"
                  src="./image/shipin.png"
                  width="100%"
                  height="100%"
                  style="object-fit: cover"
                />
                <p>欢迎关注七腾机器人</p>
              </div>
              <img :src="item.imagePath" slot="reference" />
            </el-popover>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="焦点新闻" name="two">
        <div class="news-focus">
          <div class="header">
            <el-input
              size="mini"
              class="news-search"
              placeholder="七腾大事件"
              v-model="elmentPar.searchValue"
              @keyup.enter.native="getNewsList(false)"
            >
              <i
                class="el-icon-search el-input__icon"
                slot="suffix"
                @click="getNewsList(false)"
              >
              </i>
            </el-input>
            <el-pagination
              background
              layout="prev, pager, next"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="elmentPar.pageNum"
              :page-size="elmentPar.pageSize"
              :total="newsTotal"
            >
            </el-pagination>
          </div>
          <div class="focus-con">
            <!-- 左侧新闻 -->
            <div class="con-left">
              <div
                class="news-item"
                v-for="(item, index) in newsFocusList"
                :key="index"
                :class="index != 2 ? 'item-line' : ''"
                @click="openNewsDetails(item,'mobile')"
              >
                <div class="item-img">
                  <img :src="item.newsPcImg" referrerPolicy="no-referrer" />
                </div>
                <div class="item-con">
                  <p class="con-time">
                    {{ item.issueDate }}
                  </p>
                  <el-tooltip
                    class="item"
                    :content="item.name"
                    effect="dark"
                    placement="top-start"
                  >
                    <p class="dec-title">{{ item.name }}</p>
                  </el-tooltip>
                  <p class="con-digest">{{ item.desc }}</p>
                </div>
              </div>
            </div>
            <!-- 右侧热门专栏 -->
            <div class="con-right">
              <!-- 热门新闻 -->
              <div class="hot">
                <p class="hot-title">热门新闻</p>
                <div class="hot-con">
                  <p
                    class="hot-con-item"
                    v-for="(item, index) in newsHotList"
                    :key="index"
                    @click="openNewsDetails(item,'mobile')"
                  >
                    <span> {{ item.name }}</span>
                  </p>
                </div>
              </div>

              <!-- 最新活动 -->
              <div class="activity">
                <p class="activity-title">最新活动</p>
                <div
                  class="activity-con"
                  v-for="(item, index) in newsMoreNewList"
                  :key="index"
                  @click="openNewsDetails(item,'mobile')"
                >
                  <div class="con-img">
                    <img
                      v-if="index < 2"
                      :src="item.newsPcImg"
                      referrerPolicy="no-referrer"
                    />
                  </div>
                  <div class="con-dec" v-if="index < 2">
                    <p class="dec-title">{{ item.name }}</p>
                    <p class="dec-digest">{{ item.desc }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 联系按钮 -->
        <div class="connection" @click="connection('tel:400-8765-700')">
          <i class="el-icon-more"></i>
          <p>联系</p>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>

  <!-- 手机端 -->
  <div class="news" v-else v-loading.fullscreen.lock="doSyncLoading">
    <el-collapse v-model="activeNameMobile">
      <el-collapse-item title="新闻中心" name="1">
        <ul>
          <li @click="openNew('one')">七腾视频</li>
          <li @click="openNew('two')">焦点新闻</li>
        </ul>
      </el-collapse-item>
    </el-collapse>
    <div class="news-search">
      <el-input
        size="mini"
        class="news-search-mobile"
        placeholder="七腾大事件"
        v-model="elmentPar.searchValue"
        @keyup.enter.native="getNewsList(true)"
      >
        <i
          class="el-icon-search el-input__icon"
          slot="suffix"
          @click="getNewsList(true)"
        >
        </i>
      </el-input>
    </div>
    <!-- 七腾视频 -->
    <div class="news-video-mobile" v-if="activeName === 'one'">
      <div
        class="video-item"
        v-for="(item, index) in newsFocusListMobile"
        :key="index"
        @click="openNewsDetails(item,'mobile')"
      >
        <div class="item-src" >
          <video
            muted
            playsinline
            x5-video-player-type="h5-page"
            x-webkit-airplay="true"
            webkit-playsinline="true"
            x5-video-player-fullscreen="false"
            type="video/mp4"
            :src="item.newsPcImg"
          ></video>
          <p class="item-time">{{ item.duration }}&nbsp;&nbsp;&nbsp;</p>
        </div>
        <div class="item-con">
          <el-tooltip
            class="item"
            :content="item.name"
            effect="dark"
            placement="top-start"
          >
            <p class="dec-title">{{ item.name }}</p>
          </el-tooltip>
          <p class="dec-time">
            <span>{{ item.issueDate }}</span>
          </p>
        </div>
      </div>
    </div>
    <!-- 焦点新闻 -->
    <div class="news-focus-mobile" v-else>
      <div
        class="news-item"
        v-for="(item, index) in newsFocusListMobile"
        :key="index"
        @click="openNewsDetails(item,'mobile')"
      >
        <div class="item-img">
          <img :src="item.newsPcImg" referrerPolicy="no-referrer" />
        </div>
        <div class="item-con">
          <el-tooltip
            class="item"
            :content="item.name"
            effect="dark"
            placement="top-start"
          >
            <p class="dec-title">{{ item.name }}</p>
          </el-tooltip>
          <p class="dec-digest">{{ item.desc }}</p>
          <p class="dec-time">
            {{ item.issueDate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import news from "./news.js";

export default news;
</script>
<style scoped lang="scss">
@import "~@/assets/style/common.scss";
@import "news.scss";
//全屏按钮
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
//进度条
video::-webkit-media-controls-timeline {
  display: none;
}
//观看的当前时间
video::-webkit-media-controls-current-time-display {
  display: none;
}
//剩余时间
video::-webkit-media-controls-time-remaining-display {
  display: none;
}

//音量的控制条
video::-webkit-media-controls-volume-slider {
  display: none;
}
//音量按钮
video::-webkit-media-controls-mute-button {
  display: none;
}

video::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}
</style>
