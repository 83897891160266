import request from '@/utils/request';

// 获取新闻列表
export function getNewsList(data) {
    return request({
        url: `/control/WebControlNews/list?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
        method: 'post',
        data,
    });
}

// 新增新闻列表
export function addNewsList(data) {
    return request({
        url: '/control/WebControlNews',
        method: 'post',
        data,
    });
}

// 修改新闻
export function editNewsList(data) {
    return request({
        url: '/control/WebControlNews',
        method: 'put',
        data,
    });
}


// 删除新闻
export function deleteNewsById(newsIds) {
    return request({
        url: `/control/WebControlNews/logicalDelete/${newsIds}`,
        method: 'put',
        data:{}
    });
}

// 获取微信公众号数据
export function getDoSync() {
    return request({
        url: `/control/WebControlNews/doSync`,
        method: 'get',
    });
}

// 获取新闻管理详细信息
export function getNewsDetail(newsId) {
    return request({
        url: `/control/WebControlNews/${newsId}`,
        method: 'get',
    });
}