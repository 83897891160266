import {
    getNewsList,//获取新闻列表
} from "@/api/dataSet/newsManegment.js";
export default {
    components: {
    },
    data() {
        return {
            activeName: 'one',
            elmentPar: {
                pageSize: 3,
                pageNum: 1,
                searchValue: ''
            },
            newsTotal: null,//总数量
            doSyncLoading: false,
            newsVideoSwiper: [],//七腾视频轮播图
            newsVideoTop: [],//七腾视频轮上栏
            newsVideoDown: [],//七腾视频轮下栏
            newsFocusList: [],//焦点新闻
            newsFocusListMobile: [],//新闻手机
            newsHotList: [],//热门新闻
            newsMoreNewList: [],//最新活动
            appConList: [
                {
                    url: '',
                    imagePath: require('./image/logo1.jpg'),
                    name: '公众号'
                },
                {
                    url: 'https://weibo.com/u/7732670135',
                    imagePath: require('./image/logo2.jpg'),

                }, {
                    url: ' https://v.douyin.com/iLRqddhg/ 5@1.com :2pm',
                    imagePath: require('./image/logo3.jpg'),
                }, {
                    url: '',
                    imagePath: require('./image/logo4.jpg'),
                    name: '视频号'
                }, {
                    url: 'https://www.xiaohongshu.com/user/profile/5e7063d1000000000100bc4d?xhsshare=CopyLink&appuid=5e7063d1000000000100bc4d&apptime=1704704626',
                    imagePath: require('./image/logo5.jpg'),
                }, {
                    url: 'https://www.toutiao.com/c/user/token/MS4wLjABAAAAkNOom8V1lpod6aoD0fhk904UE7_ewfZ4MdVrn8v3l9M/?source=m_redirect&wid=1704704847629',
                    imagePath: require('./image/logo6.jpg'),
                }, {
                    url: 'https://mr.baidu.com/r/1cposgDyZI4?f=cp&u=88c96917d4a0eda7',
                    imagePath: require('./image/logo7.jpg'),
                }, {
                    url: 'tel:400-8765-700',
                    imagePath: require('./image/logo8.jpg'),
                },
            ],
            mobileOpenType: 'video',
            activeNameMobile: []
        }
    },
    mounted() {
        // 改变导航栏为黑色
        document.documentElement.style.setProperty('--var-primary-color', '#000000');
        this.$store.dispatch("pageset/getbannerIndex", 1);
        // 获取七腾新闻
        if (this.$store.state.pageset.pcshow) {
            this.getNewsList(false);
        } else {
            this.getNewsList(true);
        }
    },
    methods: {
        //获取新闻数据
        async getNewsList(isMobile) {
            this.doSyncLoading = true;
            this.elmentPar.category = this.activeName === 'one' ? '七腾视频' : '焦点新闻';
            if (isMobile) {
                this.elmentPar.pageSize = 1000;
                // 如果为手机端
                let res = await getNewsList(this.elmentPar);
                this.newsFocusListMobile = res.rows;
                this.doSyncLoading = false
            } else {
                // pc端处理
                if (this.elmentPar.category === '焦点新闻') {
                    this.elmentPar.pageSize = 3;
                    this.getHotNewsList();
                } else {
                    this.elmentPar.pageSize = 11;
                }
                let res = await getNewsList(this.elmentPar);
                if (res.code === 200) {
                    // ==处理显示数据==
                    this.newsTotal = res.total;
                    this.newsVideoTop = [];
                    this.newsVideoDown = [];
                    this.newsVideoSwiper = [];
                    this.newsFocusList = res.rows;
                    res.rows.forEach((item, index) => {
                        // 左侧视频轮播。取前三项
                        if (index < 3) {
                            this.newsVideoSwiper.push(item);
                        }
                        // 右侧视频数据，取前6项目
                        if (index < 6) {
                            this.newsVideoTop.push(item);
                        }
                        // 下册视频数据，取剩下的5项
                        else if (index >= 5) {
                            this.newsVideoDown.push(item);
                        }
                    });
                    this.doSyncLoading = false;

                } else {
                    this.doSyncLoading = false;
                }
            }

        },

        //获取热门新闻数据.最新活动
        async getHotNewsList() {
            let resHot = await getNewsList({
                categorySub: '热门新闻',
                pageSize: 5,
                pageNum: 1
            });
            let resMoreNew = await getNewsList({
                categorySub: '最新活动',
                pageSize: 2,
                pageNum: 1
            });
            if (resHot.code === 200) {
                this.newsHotList = resHot.rows;
            }
            if (resMoreNew.code === 200) {
                this.newsMoreNewList = resMoreNew.rows;
            }
        },

        connection(url) {
            window.location.href = url;
        },

        handleSizeChange(val) {
            this.elmentPar.pageSize = val;
            this.getNewsList(false);
        },

        handleCurrentChange(val) {
            this.elmentPar.pageNum = val;
            this.getNewsList(false);
        },
        openNew(type) {
            this.activeName = type;
            this.activeNameMobile = [];
            this.getNewsList(true);
        },
        openNewsDetails(item,type) {
            if(type==='pc'){
                const routeUrl = this.$router.resolve({
                    path: "/newsDetail",
                    query: { id: item.newsId },
                  });
                  window.open(routeUrl.href, "_blank");
            }
            else{
                // 手机端元素打开视频号播放
                window.open(item.link)
            }
        }
    }
}